p a{
	color:blue;
}

div.articlelink p{
	font-size:80%;
}
iframe{
	margin-left:1vw;
	margin-bottom:0.5vw;
	margin-top:0.5vw;
}

.references a{
	display:block;
}

.articlelink{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	padding: 1vw;
	margin:2vh;
}
#file
{
	border-color: #bababa;
}
,
 .media
{
	min-height: 400;
}
,
 .title
{
	color: #c77800;
	padding: 10px;
}
,
 .media
{
	min-height: 400;
}
,
 .title
{
	color: #c77800;
	padding: 10px;
}
,
 .credit
{
	background-color: #ededed;
	border-bottom: 1px solid #d0d0d0;
	padding: 10;
	text-align: right;
}
,
 .credit
{
	background-color: #ededed;
	border-bottom: 1px solid #d0d0d0;
	padding: 10;
	text-align: right;
}
,
.credit
{
	background-color: #ededed;
	border-bottom: 1px solid #d0d0d0;
	padding: 10;
	text-align: right;
}
,
.dempageform{
	display:none;
	height:600vh;
	margin-left:2vw;
}
.media
{
	min-height: 400;
}
,
.title
{
	color: #c77800;
	padding: 10px;
}
.activemembers
{
	display: inline;
}
.activememberscontainer
{
	padding-bottom: 2vw;
	padding-top: 2vw;
}
.app
{
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
}

.card
{
	margin: auto;
	margin-bottom: 10px;
	margin-top: 10px;
}

.chat
{
	background: #e0e8e6;
	border-bottom: #c7cecc;
	border-bottom-width: 0.5vw;
	border-left: #c7cecc;
	border-left-width: 0.5vw;
	border-right: #c7cecc;
	border-right-width: 0.5vw;
	border-style: solid;
	border-top: #e0e8e6;
	bottom: 0;
	color: #b721ff;
	display: flex;
	position: fixed;
	transition: height 2s;
	width: 100vw;
	z-index: 4;
}
.chat textarea
{
	background: white;
	border: #c7cecc;
	border-style: solid;
	border-top-width: 5px;
	height: 75%;
	vertical-align: top;
	width: 90%;
}
.chatcoloumn1
{
	flex-direction: column;
	width: 35vw;
}
.chatcoloumn2
{
	width: 60vw;
}
.comment
{
	background: white;
	border-bottom: #f6f6f6;
	border-left: #f6f6f6;
	border-right: #f6f6f6;
	border-style: solid;
	border-top: #eaeaea;
	border-top-width: 5px;
	display: flex;
	justify-content: space-between;
	padding: 0.1vw;
}
.commp{
	display:inline;
}
.comm{
	display:inline;
}
.comment p{
	display:inline;
}

.commentform
{
	margin: 1vw;
}
.commentinput
{
	width: 70vw;
}
.nameinput
{
	width: 20vw;
}
.emailinput
{
	width: 20vw;
}
.eventbox
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	padding: 1vw;
	width: 90vw;
}
.eventcol1
{
	display: block;
	margin: 1vw;
	text-align: center;
	width: 100%;
}
.eventcol2
{
	height: 50vh;
	overflow: hidden;
	width: 50%;
}
.eventcol3
{
	height: 50vh;
	width: 50%;
}
.eventcol3inner
{
	height: 100%;
	width: 100%;
}
.eventformbox
{
	margin: 1vw;
}
.eventformbox>input
{
	display: block;
}
.eventformbox>label
{
	display: block;
}
.eventformbox>p
{
	display: inline;
	margin-right: 1vw;
}
.eventformbox>select
{
	display: inline;
}
.eventimagemapcontainer
{
	display: flex;
	justify-content: space-around;
	width: 90vw;
}
.form
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	display: flex;
	justify-content: space-between;
	padding: 1vw;
}
.form-control
{
	padding-bottom: 1vw;
}
.group
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 3px;
	margin-top: 1vw;
	padding: 1vw;
}
.groupdiv
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	color: white;
	height: 12vh;
	margin: 0.5vw;
	overflow: hidden;
	padding: 0.5vw;
	position: relative;
	width: 26.5vw;
}
.navbuttons{
	display:inline;
	transform:translateY(-25%)
}

.groupstitle{
	margin-top:2vw;
	margin-bottom:0vw;
}

.groupslist{
	margin-left:5vw;
	margin-right:5vw;
	width:90vw;
	transform:translateY(-2%);
}

.menu{
	/* transform:translateY(-10%); */
	height:6vh;
	padding:2vw;
	width:100vw;
}

.menutitle{
	margin-left:3vw;
	color:white;
	display:inline;
	transform:translateY(80%);
}


.vids{
	display:block;
}

.homeimg{
width:100vw;
}

.groupdivone
{
	border: #eaeaea;
	border-bottom-width: 3px;
	border-left-width: 0px;
	border-right-width: 0px;
	border-style: solid;
	border-top-width: 0px;
}
.groupdivtwo
{
	border: #eaeaea;
	border-bottom-width: 3px;
	border-left-width: 0px;
	border-right-width: 0px;
	border-style: solid;
	border-top-width: 0px;
}
.groupsbox
{
	display: flex;
}
.groupscol1
{
	border: #eaeaea;
	border-bottom-width: 3px;
	border-left-width: 3px;
	border-right-width: 3px;
	border-style: solid;
	border-top-width: 3px;
	width: 100%;
}
.homepage
{
	padding: 3vw;
	padding-top: 7vw;
	width: 93vw;
}
.homepageexplanation
{
	background: white;
	border-radius: 10px;
	margin-bottom: 2vh;
	margin-top: 2vh;
	opacity: 0.9;
	padding-bottom: 2vh;
	padding-left: 2vw;
	padding-right: 2vw;
	padding-top: 2vh;
}
.homepageform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	display: flex;
	justify-content: space-between;
	padding: 1vw;
	width: 90vw;
}
.homepageform div
{
	margin: 1vw;
}
.homepageform input
{
	display: inline;
}
.homepageform label
{
	display: inline;
	margin: 1vw;
}
.homepageform select
{
	display: inline;
}
.image
{
	border-radius: 10px;
	height: 600px;
	width: 1000px;
}
.image
{
	border-radius: 10px;
	height: 600px;
	width: 1000px;
}
.image
{
	border-radius: 10px;
	height: 600px;
	width: 1000px;
}
.innersigninform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	padding: 1vw;
	width: 30vw;
	margin-top: 7vw;
}
.innersignupform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	margin-bottom: 7vw;
	padding: 1vw;
	width: 60vw;
}
.juryboxform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-left-width: 5px;
	border-right-width: 5px;
	border-style: solid;
	border-top-width: 5px;
	padding: 1vw;
}
.juryform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	display: flex;
	justify-content: space-between;
	padding: 1vw;
	width: 90vw;
}
.leadbox
{
	background: #f6f6f6;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	display: flex;
	margin: 1vw;
	padding: 1vw;
}
.leadcol1
{
	width: 50%;
}
.leadcol2
{
	display: flex;
	justify-content: flex-end;
	width: 50%;
}
.leader
{
	background: white;
	border: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	display: inline-block;
	margin-right: 1vw;
	margin-top: 1vh;
	padding: 0vw;
}
.leaflet-container
{
	height: 100%;
	width: 100%;
	z-index:3;
}

.left-arrow
{
	color: #000;
	cursor: pointer;
	font-size: 1rem;
	left: 32px;
	position: absolute;
	top: 50%;
	user-select: none;
	z-index: 10;
}
.percentage
{
	background-color: red;
	height: 3vh;
}
.percentagecontainer
{
	overflow:hidden;
	border-color: black;
	border-style: solid;
	border-width: 2px;
	height: 3vh;
	width: 98%;
}
.pollbox
{
	background: #f6f6f6;
	border: #eaeaea;
	border-left-width: 5px;
	border-right-width: 5px;
	border-style: solid;
	border-top-width: 5px;
	padding: 1vw;
}
.pollform
{
	margin-bottom: 1vw;
}
.pollsuggestion
{
	background: #f6f6f6;
	border: white;
	border-style: solid;
	border-width: 8px;
	margin: 0.2vw;
	padding: 0.2vw;
}
.postbox
{
	background: white;
	border: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	margin-top: 1vw;
}
.postboxform
{
	background: #f6f6f6;
	border: #eaeaea;
	border-left-width: 5px;
	border-right-width: 5px;
	border-style: solid;
	border-top-width: 5px;
	padding: 1vw;
}

.restrictionexplanation
{
	transition: height 4s;
}
.reviewscroll
{
	background-color: blanchedalmond;
	height: 300px;
	margin: auto;
	overflow: auto;
	width: 500px;
}
.right-arrow
{
	color: #000;
	cursor: pointer;
	font-size: 1rem;
	position: absolute;
	right: 32px;
	top: 50%;
	user-select: none;
	z-index: 10;
}
.right-arrow
{
	color: #000;
	cursor: pointer;
	font-size: 1rem;
	position: absolute;
	right: 32px;
	top: 50%;
	user-select: none;
	z-index: 10;
}
.right-arrow
{
	color: #000;
	cursor: pointer;
	font-size: 1rem;
	position: absolute;
	right: 32px;
	top: 50%;
	user-select: none;
	z-index: 10;
}
.rule
{
	background: white;
	border: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	margin-top: 1vw;
	padding: 0.5vw;
}
.ruletext
{
	display: inline;
}
.signinform
{
	display: flex;
	height: auto;
	justify-content: center;
	width: 100vw;
}
.signininput
{
	display: flex;
	margin: 0.5vw;
	text-align: left;
	align-items:center;
}

.signupform
{
	display: flex;
	justify-content: center;
	margin-bottom: 7vw;
	margin-top: 7vw;
	width: 100vw;
}
.signupinput
{
	display: flex;
	justify-content: space-between;
	align-items:center;
	margin: 0.5vw;
}
.slide
{
	opacity: 0;
	transition-duration: 1s ease;
}
.slide
{
	opacity: 0;
	transition-duration: 1s ease;
}
.slide
{
	opacity: 0;
	transition-duration: 1s ease;
}
.slide.active
{
	opacity: 1;
	transform: scale(1.08);
	transition-duration: 1s;
}
.slide.active
{
	opacity: 1;
	transform: scale(1.08);
	transition-duration: 1s;
}
.slide.active
{
	opacity: 1;
	transform: scale(1.08);
	transition-duration: 1s;
}
.slider
{
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: relative;
}
.slider
{
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
}
.slider
{
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: relative;
}
.submitbutton
{
	margin-left: 2vw;
}
.tabs
{
	color: #396f6b;
	margin-left: 4vw;
	margin-right: 4vw;
	margin-bottom: 4vw;
	width: 91vw;
}
.titlediv
{
	width: 100%;
}
.togglechatbutton
{
	background-color: #e0e8e6;
	border-left: #c7cecc;
	border-right: #c7cecc;
	border-style: solid;
	border-top: #c7cecc;
	border-width: 5px;
	color: #b721ff;
	display: flex;
	height: 5vh;
	justify-content: center;
	position: fixed;
	transition: bottom 2s;
	width: 100vw;
}
.togglechatbutton button
{
	height: 3.5vh;
}
.usercard
{
	background: #d4dfee;
	border-radius: 10px;
	box-shadow: 5px 5px 5px #050A30;
	color: white;
	height: 45vh;
	margin-bottom: 2vw;
	margin-right: 2vw;
	overflow: hidden;
	position: relative;
	width: 44vw;
}
.users
{
	display: flex;
	flex-wrap: wrap;
}
.yourgroups
{
	border: #eaeaea;
	border-style: solid;
	border-width: 3px;
	margin-bottom: 1vw;
}
a
{
	color: white;
	font-weight: bold;
	text-decoration: none;
}

body
{
	align-items: left;
	background: white;
	display: flex;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
 "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
 sans-serif;
	justify-content: left;
	margin: 0;
	moz-osx-font-smoothing: grayscale;
	padding: 0;
	webkit-font-smoothing: antialiased;
}
button
{
	background: #6699cc;
	border: 2px solid white;
	border-radius: 10px;
	box-shadow: 2px 2px 2px 1px #050A30;
	color: white;
	margin: 5px;
	padding-left: 5px;
}
button:hover
{
	background: #6699cc;
	border-radius: 10px;
	box-shadow: 0px 0px #050A30;
	color: white;
	transform: translate(2px, 2px);
}
code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
 monospace;
}
code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
 monospace;
}
code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
 monospace;
}
h3,h5,h4,h6,h2,h1
{
	color: #56a7a1;
}
input
{
	height:4vh;
	border-color: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	overflow: auto;
	width: 86vw;
}

input[type="checkbox"] {
  margin: 0;
  font: inherit;
  width: 5vw;
  height: 10vw;
  border: 0.15em solid #6699cc;
  border-radius: 0.15em;
}

input.error
{
	border-color: red;
}
input.error
{
	border-color: red;
}
input.error
{
	border-color: red;
}
input:focus
{
	border-color: #bababa;
}
input[type="file"]
{
	border-style: none;
}
label
{
	color: #43827d;
}
p
{
	color: #009688;
}
p
{
	color: #009688;
}
p
{
	color: #43827d;
}
select
{
	border-color: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	overflow: auto;
	width: 10vw;
}
select:focus
{
	border-color: #bababa;
	outline: none !important;
}
textarea
{
	border-color: #D3D3D3;
	border-style: solid;
	border-width: 5px;
	overflow: auto;
	width: 87vw;
}
textarea:focus
{
	border-color: #f6f6f6;
	outline: none !important;
}


.chat select{
 width:16vw;
}

.commentinput{
	width:60vw;
	display:inline;
	margin:1vw;
}

.commentstuff{
	display:inline;
	margin:1vw;
}
.articlecomments
{
	background: white;
	border: #eaeaea;
	border-style: solid;
	border-width: 5px;
	padding-bottom: 0.3vw;
	padding-top: 1vh;
	margin-top: 2vh;
}
.comments
{
	background: white;
	border-bottom: #eaeaea;
	border-left: #eaeaea;
	border-right: #eaeaea;
	border-style: solid;
	border-top: #d4d4d4;
	border-width: 5px;
	padding-bottom: 0.3vw;
	padding-top: 0.3vw;
}
.commentsdisplay{
	overflow-y: scroll;
	max-height:20vw;
}

.details{
	display:flex;
	justify-content:space-around;
	margin-bottom:10vw;
}
.detailscol1{
 width:50%;
}
.detailscol2{
	width:50%;
	text-align:center;
}

.detailsimg{
	object-fit:cover;
	position:relative;
	box-shadow:2px 2px 2px 4px #050A30;
	max-height:40vh;
}

.leaderexpl{
	transition:max-height 2s;
}

.posttextarea{
	width:83vw;
}

.eventformbox input{
	display:inline;
	width:70vw;
}
.eventformbox select{
	display:inline;
	width:70vw;
}
.homepageimg{
	width:100%;
	height:100%;
	object-fit:cover;
	position:absolute;
	opacity:0.4;
	bottom:0px;
	z-index:-1;
}
.socialicons{
	margin-left:30vw;
}

.infovid{
	width:50vw;
	height:65vh;
	display:block;
}
@media only screen and (max-width: 1250px) {
	.socialicons{
		margin-left:25vw;
	}
}
@media only screen and (max-width: 1200px) {
	.socialicons{
		margin-left:23vw;
	}
}
@media only screen and (max-width: 1120px) {
	.socialicons{
		margin-left:18vw;
	}
}
@media only screen and (max-width: 1050px) {
	.socialicons{
		margin-left:13vw;
	}
}
@media only screen and (max-width: 980px) {
	.socialicons{
		margin-left:10vw;
	}
}

@media only screen and (max-width: 900px) {
	.socialicons{
		margin-left:8vw;
	}
}
@media only screen and (max-width: 850px) {
	.socialicons{
		margin-left:6vw;
	}
}
@media only screen and (max-width: 770px) {
	.socialicons{
		margin-left:4vw;
	}
}
@media only screen and (max-width: 750px) {
	.socialicons{
		margin-left:2vw;
	}
}
@media only screen and (max-width: 730px) {
	.socialicons{
		margin-left:0vw;
	}
}
@media only screen and (max-width: 920px) {
	.menu{
		height:14vh;
	}
}
@media only screen and (max-width: 420px) {
	.menu{
		height:20vh;
	}
}
@media only screen and (max-width: 500px) {

}
@media only screen and (max-width: 910px) {

	.eventformbox input{
		display:inline;
		width:60vw;
	}
	.eventformbox select{
		display:inline;
		width:60vw;
	}
}

@media only screen and (max-width: 640px) {
	.details{
		display:block;
		margin-bottom:15vw;
	}
	.detailscol1{
	 width:100%;
	}
	.detailscol2{
		width:100%;
	}
	.detailsimg{
		width:90vw;
	}
	.eventformbox input{
		display:inline;
		width:55vw;
	}
	.eventformbox select{
		display:inline;
		width:55vw;
	}
}

@media only screen and (max-width: 510px) {
	.details{
		display:block;
		margin-bottom:20vw;
	}
	.eventformbox input{
		display:inline;
		width:50vw;
	}
	.eventformbox select{
		display:inline;
		width:50vw;
	}
}
.iframeleft{
width:37vw;
height:40vh;
display:inline;
}
.iframeright{
margin-left:5vw;
width:37vw;
height:40vh;
display:inline;
}
.dempage{
	width:40vw;
	display:inline;
}
.dempage iframe{
	width:40vw;
	height:55vh;
	display:inline;
}
.dempageform{
	display:none;
	height:600vh;
	margin-left:2vw;
}
@media only screen and (max-width: 450px) {
	.dempageform{
		display:block;
	}
	.dempage{
		width:90vw;
		display:block;
	}
	.dempage iframe{
		width:90vw;
		height:40vh;
		display:block;
	}
	.infovid{
		width:70vw;
		height:30vh;
	}
	.homepageimg{
		bottom:0px;
		width:170%;
		height:50%;
		object-fit:contain;
	}

	.eventformbox input{
		display:inline;
		width:40vw;
	}
	.eventformbox select{
		display:inline;
		width:40vw;
	}
	.iframeleft{
		width:90vw;
		display:block;
		margin-left:0vw;
	}
	.iframeright{
		width:90vw;
		display:block;
		margin-left:0vw;
	}
}



@media only screen and (max-width: 350px) {

	.eventformbox input{
		display:inline;
		width:30vw;
	}
	.eventformbox select{
		display:inline;
		width:30vw;
	}
}

@media only screen and (max-width: 890px) {
	.commentinput{
		width:55vw;
	}
}
@media only screen and (max-width: 800px) {
	.groupdiv
	{
		width: 25vw;
	}
	.commentinput{
		width:50vw;
	}
}
@media only screen and (max-width: 700px) {
	.commentinput{
		width:45vw;
	}
}
@media only screen and (max-width: 600px) {
	.commentinput{
		width:40vw;
	}
}
@media only screen and (max-width: 500px) {
	.commentinput{
		width:35vw;
	}
}
@media only screen and (max-width: 450px) {
	.commentstuff{
		display:block;
		margin:2vw;
	}
	.commentinput{
		width:75vw;
		display:block;
	}
}

@media only screen and (max-width: 413px) {
	.postboxform{
		display:block;
	}
	.comment{
		display:block;
	}
	.comm{
		display:block;
	}
  .commp{
		display:block;
	}
	.detailsimg{
		object-fit:contain;
	}
.chat textarea{
	height:70%;
}
.chat select{
 width:31vw;
}
	.homeimg{
		height:100vh;
		width:auto;
		transform:translate(-50%,-2%);
	}
	.innersigninform{
		width:80vw;
		color:red;
	}
	.innersignupform{
		width:80vw;
	}

	.groupslist{
		transform:translateY(-3%);
		margin-left:3vw;
		margin-right:3vw;
		width:94vw;
	}
	.groupstitle{
		padding-top:5vh;
		margin:0vw;
	}
	.groupdiv
	{
		background: #f6f6f6;
		border: #eaeaea;
		border-style: solid;
		border-width: 5px;
		color: white;
		height: 20vh;
		margin: 0.5vw;
		overflow: hidden;
		padding: 0.5vw;
		position: relative;
		width: 25vw;
	}

	.navbuttons{
		display:block;
		transform:translateY(10%)
	}
}

@media only screen and (max-width: 370px) {
	.chat textarea{
		height:64%;
	}
}

#startTimeValue{
	overflow-x:hidden;
}
#endTimeValue{
	overflow-x:hidden;
}
